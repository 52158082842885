import {createStore, createEvent} from "effector";
import {ICharacteristic} from "../interfaces/ICharacteristic";

export const initCharacteristics = createEvent<ICharacteristic[]>()
export const resetValues = createEvent()
export const updateCharacteristic = createEvent<ICharacteristic>()

const resetValuesReducer = (state: ICharacteristic[]) =>
    state.map(characteristic => { return  { ...characteristic, priority: 0, value: 0 }})

const updateCharacteristicReducer = ( state: ICharacteristic[], newCharacteristic: ICharacteristic) =>
    state.map((char) => { return char.id === newCharacteristic.id? newCharacteristic : char})


export const $characteristics = createStore<ICharacteristic[]>([])
    .on(initCharacteristics, (state, arrayCharacteristics) => arrayCharacteristics)
    .on(resetValues, resetValuesReducer)
    .on(updateCharacteristic, updateCharacteristicReducer)
