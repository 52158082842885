import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Rating from '@material-ui/lab/Rating';
import TextField from '@material-ui/core/TextField';
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import {ICharacteristic} from "../interfaces/ICharacteristic";
import {Checkbox, withStyles} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {updateCharacteristic} from "../store/CharacteristicsValuesStore";
import {theme} from "../helpers/theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputLine: {
            width: theme.spacing(12),
            paddingLeft: theme.spacing(1)
        },
        inputCheckbox: {
            transform: "scale(1.2)",
        },
        nameTitle: {
            fontSize: '1rem'
        }
    }),
);

const StyledRating = withStyles({
    iconFilled: {
        color: theme.palette.primary.main,
    },
    iconHover: {
        color: theme.palette.primary.dark,
    },
})(Rating);


type RowCharacteristicProps = {
    row: ICharacteristic
}

export const RowCharacteristic: React.FC<RowCharacteristicProps> = props => {

    const { row } = props

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateCharacteristic({ ...row, value: event.target.checked? 1:0})}

    const classes = useStyles();

    return (
        <TableRow key={row.id}>
            <TableCell component="th" scope="row" className={classes.nameTitle}>
                {row.name}
            </TableCell>
            <TableCell align="center">
                <StyledRating icon={<FiberManualRecordSharpIcon/>} value={row.priority}
                              onChange={(e, value) => updateCharacteristic({...row, priority: value}) } />
            </TableCell>
            <TableCell align="center">
                { props.row.type.id === 1 && <Checkbox
                    checked={!!row.value}
                    onChange={handleCheckboxChange}
                    color="default"
                    className={classes.inputCheckbox}
                /> }

                { props.row.type.id === 2 && <TextField
                        id="standard-number"
                        type="number"
                        value={row.value}
                        variant={'outlined'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ step: 0.1 }}
                        onChange={(e) =>
                            updateCharacteristic({ ...row, value: parseFloat(e.target.value) })}
                        className={classes.inputLine}
                    /> }
            </TableCell>
        </TableRow>
    )
}