import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Grid, Paper, Typography} from "@material-ui/core";
import {IClinicalResearchDto} from "../interfaces/IResearch";
import {ListResearches} from "./ListResearches";

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '80%',
        },
        tabsBar: {
            marginBottom: theme.spacing(1)
        },
        notFoundTitle: {
            textAlign: 'center',
            margin: theme.spacing(3)
        }
    }),
);

type TabsListsResearchesProps = {
    researches: IClinicalResearchDto[]
}

export const TabsListsResearches: React.FC<TabsListsResearchesProps> = props => {
    const classes = useStyles();
    const [index, setIndex] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndex(newValue);
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
        >
            <Paper className={classes.root}>
                <AppBar position="static" color="default" className={classes.tabsBar}>
                    <Tabs
                        value={index}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs"
                    >
                        {props.researches.map((item, index) => (
                            <Tab label={`${index + 1}) ${item.name}`} {...a11yProps(item.id)} />
                        ))}
                    </Tabs>
                </AppBar>

                {props.researches.length !== 0 ?
                    <ListResearches
                        researchesInfo={props.researches[index] ? props.researches[index].researchInfo : []}/> :
                    <Typography className={classes.notFoundTitle} variant={'h5'}>Исследования не найдены</Typography>
                }
            </Paper>
        </Grid>
    );
}