import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {ICharacteristic} from "../interfaces/ICharacteristic";
import {RowCharacteristic} from "./RowCharacteristic";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerTitle: {
            fontSize: '1.1rem'
        }
    }),
);

type ListCharacteristicsProps = {
    characteristics: ICharacteristic[]
}

export const ListCharacteristics: React.FC<ListCharacteristicsProps> = props => {

    const classes = useStyles();

    return (
        <Table aria-label="customized table">
            <TableHead>
                <TableRow>
                    <TableCell className={classes.headerTitle} align="center">Характеристика</TableCell>
                    <TableCell className={classes.headerTitle} align="center">Значимость</TableCell>
                    <TableCell className={classes.headerTitle} align="center">Значение</TableCell>
                </TableRow>
            </TableHead>

            <colgroup>
                <col style={{width: '60%'}}/>
                <col style={{width: '20%'}}/>
                <col style={{width: '20%'}}/>
            </colgroup>


            <TableBody>
                {props.characteristics.map((row) => (
                    <RowCharacteristic row={row}/>
                ))}
            </TableBody>
        </Table>
    );
}
