import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {IResearchInfo} from "../interfaces/IResearch";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerTitle: {
            fontSize: 17
        },
        notFoundTitle: {
            textAlign: 'center',
            margin: theme.spacing(3)
        },
        nameStatistic: {
            fontSize: 16
        }
    }),
);

type ListResearchesProps = {
    researchesInfo: IResearchInfo[]
}

export const ListResearches: React.FC<ListResearchesProps> = props => {
    const classes = useStyles();

    return (
        <>
            {
                props.researchesInfo.length !== 0 ?
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.headerTitle} align="center">Статистические показатели</TableCell>
                                <TableCell className={classes.headerTitle} align="center">Значения</TableCell>
                            </TableRow>
                        </TableHead>

                        <colgroup>
                            <col style={{width: '70%'}}/>
                            <col style={{width: '30%'}}/>

                        </colgroup>

                        <TableBody>
                            {props.researchesInfo.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell className={classes.nameStatistic} component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">{row.value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table> :
                    <Typography className={classes.notFoundTitle} variant={'h5'}>Статистические показатели не найдены</Typography>
            }
        </>
    )
}