import {Button} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import React, {useEffect} from "react";
import { routes } from '../helpers/paths'
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useHistory, useLocation} from "react-router-dom";
import { TabsListsResearches } from "../components/TabsListsResearches";
import {requests} from "../helpers/requests";
import {IClinicalResearchDto} from "../interfaces/IResearch";
import {useHttp} from "../hooks/useHttp";
import {Loader} from "../components/Loader";
import {ICharacteristic} from "../interfaces/ICharacteristic";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backBtn: {
            margin: theme.spacing(3, 3)
        },
    }),
);

export const ResearchesPage = (): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const [clinicalResearches, setClinicalResearches] = React.useState<IClinicalResearchDto[]>([])
    const { request, loading } = useHttp()
    const {state} = useLocation<ICharacteristic[]>();

    const requestBody = state.map((char) => { return {characteristicId: char.id, value: char.value, priority: char.priority}})

    useEffect(() => {
        request(requests.searchCharacteristic.url, requests.searchCharacteristic.method, requestBody, null)
            .then( (data) => {
                setClinicalResearches(data.map( (item: any): IClinicalResearchDto =>
                { return {id: item.clinicalResearchDto.id, name: item.clinicalResearchDto.name, researchInfo: item.clinicalResearchDto.researchInfo}} ))
            })
    }, [])

    return (
        <div>
            <Button startIcon={<ArrowBack/>}
                    className={classes.backBtn}
                    onClick={() => (history.push(routes.toCharacteristics))}>Изменить характеристики</Button>

            { loading ? <Loader /> : <TabsListsResearches researches={clinicalResearches} /> }
        </div>
    )
}