import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00afb6',
            dark: '#02a5ab'
        },
    },
});
