import React from "react";
import {Typography, CircularProgress, makeStyles} from "@material-ui/core";
import {createStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        progressClass:{
            color: theme.palette.primary.main,
            marginRight: theme.spacing(3)
        },
        divLoader: {
            display: "flex",
            justifyContent: "center",
            height: "auto",
            width: "auto",
            margin: theme.spacing(10, 0)
        }
    }),
);

export const Loader: React.FC = () => {
    const classes = useStyles();

    return(
        <div className={classes.divLoader}>
            <CircularProgress className={classes.progressClass}/>
            <Typography variant={"h5"}>Загрузка данных</Typography>
        </div>
    )
}
