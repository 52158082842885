import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import {theme} from './helpers/theme'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import {CharacteristicsPage} from "./pages/CharacteristicsPage";
import {ResearchesPage} from "./pages/ResearchesPage";
import {routes} from "./helpers/paths";

function App(): JSX.Element {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route component={CharacteristicsPage} path={routes.toCharacteristics} exact/>
                    <Route component={ResearchesPage} path={routes.toResearches}/>
                    <Redirect to={routes.toCharacteristics} />
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
